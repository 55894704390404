<template>
    <div class="container mt-3 text-center">
        <div class="row rounded-3 align-items-center fw-bold">
            <div class="col gx-0 text-start ps-2" />
            <div class="col gx-0">
                {{ bankName }}<sup><a href="#footnote_3">3</a></sup>
            </div>
            <div class="col gx-0 text-end pe-2">
                Aven
            </div>
        </div>
        <div class="row bg-light rounded-3 py-2 mt-1">
            <div class="col gx-0 text-start ps-2">
                Mid APR
            </div>
            <div class="col gx-0">
                {{ midApr }}
            </div>
            <div class="col gx-0 fw-bold text-end pe-2">
                7.99%
            </div>
        </div>
        <div class="row rounded-3 py-2">
            <div class="col gx-0 text-start ps-2">
                Monthly
            </div>
            <div class="col gx-0">
                {{ monthly }}
            </div>
            <div class="col gx-0 fw-bold text-end pe-2">
                $313.32
            </div>
        </div>
        <div class="row bg-light rounded-3 py-2">
            <div class="col gx-0 text-start ps-2">
                Total
            </div>
            <div class="col gx-0">
                {{ total }}
            </div>
            <div class="col gx-0 fw-bold text-end pe-2">
                $11,279.43
            </div>
        </div>
        <div class="row rounded-3 py-2 text-danger">
            <div class="col gx-0 text-start ps-2">
                Unnecessary Interest Paid
            </div>
            <div class="col gx-0">
                {{ saved }}
            </div>
            <div class="col gx-0" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CardComparison',
        props: {
            bankName: { type: String, require: true },
            color: { type: String, require: true },
            midApr: { type: String, require: true },
            monthly: { type: String, require: true },
            total: { type: String, require: true },
            saved: { type: String, require: true },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/cardComparison';
</style>
