<template>
    <section-block>
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="text-responsive text-center">
                        Save up to 50% interest.
                        <br>
                        Every month.<sup><a href="#footnote_2">2</a></sup>
                    </h2>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-10 offset-md-1">
                    <h5 class="text-responsive text-center fw-light">
                        Stop paying thousands a year in interest from your high interest debt, like a credit card or personal loan. See the savings on a $10,000 balance if paying off over 3 years.<sup><a href="#footnote_3">3</a></sup>
                    </h5>
                </div>
            </div>

            <div
                id="comparisonCarousel"
                class="carousel slide mt-3 d-md-none"
                data-ride="carousel"
            >
                <div class="carousel-inner rounded-3">
                    <div class="carousel-item active">
                        <card-comparison
                            bank-name="Captial One Premium"
                            mid-apr="27.24%"
                            monthly="$409.54"
                            total="$14,743.47"
                            saved="$3,464.04"
                        />
                    </div>
                    <div class="carousel-item">
                        <card-comparison
                            bank-name="Chase Freedom"
                            mid-apr="19.36%"
                            monthly="$368.38"
                            total="$13,261.78"
                            saved="$1,982.35"
                        />
                    </div>
                    <div class="carousel-item">
                        <card-comparison
                            bank-name="BofA Cash Rewards"
                            mid-apr="19.24%"
                            monthly="$367.77"
                            total="$13,239.89"
                            saved="$1,960.46"
                        />
                    </div>
                </div>
            </div>

            <div class="row mt-md-5 d-none d-md-block">
                <div class="col-12 text-center">
                    <div class="row rounded-3 p-2 align-items-center fw-bold">
                        <div class="col-4" />
                        <div class="col-2">
                            Aven
                        </div>
                        <div class="col-2">
                            Capital One Platinum
                        </div>
                        <div class="col-2">
                            Chase Freedom
                        </div>
                        <div class="col-2">
                            BofA Cash Rewards
                        </div>
                    </div>
                    <div class="row bg-light rounded-3 p-2">
                        <div class="col-4 text-start">
                            Midpoint APR
                        </div>
                        <div class="col-2 fw-bold">
                            7.99%
                        </div>
                        <div class="col-2">
                            27.24%
                        </div>
                        <div class="col-2">
                            19.36%
                        </div>
                        <div class="col-2">
                            19.24%
                        </div>
                    </div>
                    <div class="row rounded-3 p-2">
                        <div class="col-4 text-start">
                            Monthly Payment
                        </div>
                        <div class="col-2 fw-bold">
                            $313.32
                        </div>
                        <div class="col-2">
                            $409.54
                        </div>
                        <div class="col-2">
                            $368.38
                        </div>
                        <div class="col-2">
                            $367.77
                        </div>
                    </div>
                    <div class="row bg-light rounded-3 p-2">
                        <div class="col-4 text-start">
                            Total Paid
                        </div>
                        <div class="col-2 fw-bold">
                            $11,279.43
                        </div>
                        <div class="col-2">
                            $14,743.47
                        </div>
                        <div class="col-2">
                            $13,261.78
                        </div>
                        <div class="col-2">
                            $13,239.89
                        </div>
                    </div>
                    <div class="row rounded-3 p-2">
                        <div class="col-4 text-start">
                            Unnecessary Interest Paid
                        </div>
                        <div class="col-2 fw-bold">
                            $0.00
                        </div>
                        <div class="col-2 text-danger">
                            $3,464.04
                        </div>
                        <div class="col-2 text-danger">
                            $1,982.35
                        </div>
                        <div class="col-2 text-danger">
                            $1,960.46
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <p class="small mt-3 text-muted text-md-center">
                        The Unnecessary Interest Paid is the interest we will save you over those banks. Estimate assumes no additional transactions are made and the suggested “monthly” payment is
                        paid every month for 3 years. Paying only the min payment of 1% principal & interest increases the “Total” amount paid and will not pay off the balance in 3 years. See
                        <a
                            href="#"
                            @click="getDocument(LegalDocumentTypes.earlyHELOCDisclosure)"
                        >Min Payment Example</a> for repayment using min payment.
                    </p>
                </div>
            </div>
        </div>
    </section-block>
</template>

<script>
    import { getDocumentForSessionAndOpen } from '@/utils/document'
    import { logger } from '@/utils/logger'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import SectionBlock from '@/components/SectionBlock'
    import { LegalDocumentTypes } from '@/services/api'
    import CardComparison from '@/components/CardComparison'
    import { Carousel } from 'bootstrap'

    export default {
        name: 'CardComparisonSection',
        components: {
            CardComparison,
            'section-block': SectionBlock,
        },
        data: function () {
            return {
                LegalDocumentTypes,
            }
        },
        mounted: function () {
            const myCarousel = document.getElementById('comparisonCarousel')
            const carousel = new Carousel(myCarousel, {
                interval: 2000,
            })
            carousel.cycle()
        },
        methods: {
            getDocument: async function (docType) {
                try {
                    this.$logEvent('click_link_get_document', { docType })
                    await getDocumentForSessionAndOpen(docType)
                } catch (e) {
                    logger.error(`failed to open pdfs document `, e)
                    ApiErrorHandler(e)
                }
            },
        },
    }
</script>
