<template>
    <section-block
        class="mb-6 mt-0 text-center"
        v-if="currentSavingsBalance > 0"
    >
        <h1 class="ticker-title">
            <sup class="fw-bold">$</sup>{{ parseFloat(counter).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
        </h1>
        <p>
            Interest saved with Aven<sup><a href="#footnote_6">6</a></sup>
        </p>
    </section-block>
</template>

<script>
    import SectionBlock from '@/components/SectionBlock'
    import { getSavingsForTicker } from '@/services/api'
    export default {
        name: 'TickerSection',
        components: { SectionBlock },
        data: function () {
            return {
                currentSavingsBalance: 0,
                lastSavingsBalance: 0,
                counter: 0,
            }
        },
        beforeDestroy: function () {
            clearInterval(this.intervalId)
        },
        async mounted() {
            const successful = await this.getCumulativeBalance()
            if (!successful) {
                return
            }

            this.counter = this.lastSavingsBalance // we'll start at the balance that was 24 hours ago
            const diffBetweenAmounts = this.currentSavingsBalance - this.lastSavingsBalance // get the difference between the two balances so we can count up to this much
            const amountToCountUpBy = (diffBetweenAmounts / 1440).toFixed() // divide by 24 hours (in seconds)
            this.intervalId = setInterval(() => {
                if (!this.currentSavingsBalance) {
                    // since this mounts before we get a value, we can silently return the interval (until we get a value or it unmounts in beforeDestroy)
                    return
                }

                if (parseInt(this.counter) > this.currentSavingsBalance) {
                    // we don't want to count up past the current balance cuz it wouldn't be real life
                    return
                }

                const immersiveRandomDecimal = Math.floor(Math.random() * 99) / 100 // better ux to provide some random decimal values to tack on the end
                this.counter = (parseFloat(this.counter) + parseFloat(amountToCountUpBy) + immersiveRandomDecimal).toFixed(2)
            }, 1000)
        },
        methods: {
            getCumulativeBalance: async function () {
                const response = await getSavingsForTicker()
                if (!response.data?.success) {
                    // greetings, on call, if you encounter this error, you may need to call endpoint to get cumulative balances (daemon)
                    console.warn('There is no data for the latest savings ticker component')
                    return false
                }
                this.currentSavingsBalance = response.data.payload.currentSavingsBalance
                this.lastSavingsBalance = response.data.payload.lastSavingsBalance
                return true
            },
        },
    }
</script>

<style lang="scss">
    @import '../../styles/components/tickerSection.scss';
</style>
