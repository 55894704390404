<template>
    <div class="play-button-container">
        <img
            src="@/assets/images/components/videoPlayer/video-play.svg"
            width="96"
            height="67"
            alt="play"
        >
    </div>
</template>

<script>
    export default {
        name: 'VideoPlayerPlayButton',
        props: {
            text: {
                type: String,
                default: 'Play Video',
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/videoPlayerPlayButton';
</style>
