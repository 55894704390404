<template>
    <div class="item">
        <div class="rating mb-2">
            <div v-if="theme === 'trustpilot'">
                <div
                    class="p-0"
                    :style="{ height: '24px' }"
                >
                    <img
                        v-for="index in rating"
                        :src="ratingStarSvg"
                        alt="star"
                        class="five-star-item"
                        :key="index"
                    >
                </div>
            </div>
            <div v-else>
                <img
                    v-for="index in rating"
                    :src="testimonialStarSvg"
                    width="24"
                    height="24"
                    alt="5 Star Review"
                    :key="index"
                >
            </div>
        </div>
        <p
            v-for="contentParagraph in contentParagraphs"
            :key="contentParagraph"
        >
            {{ contentParagraph }}
        </p>
        <p class="small">
            <strong>{{ name }}</strong><br>
            {{ state }}
        </p>
    </div>
</template>

<script>
    export default {
        name: 'Review',
        props: {
            name: {
                type: String,
                required: true,
            },
            state: {
                type: String,
                required: false,
            },
            rating: {
                type: Number,
                required: true,
            },
            content: {
                type: String,
                required: false,
            },
            theme: {
                type: String,
                required: false,
                default: '',
            },
        },
        computed: {
            contentParagraphs: function () {
                const content = this.content
                if (typeof content !== 'string') {
                    return []
                }

                // We consider a new paragraph is at least two new lines surrounded by any whitespace
                const contentParagraphs = content.split(/\s*\n\s*\n[\n|\s]*/)

                return contentParagraphs
            },
            ratingStarSvg: function () {
                return require('@/assets/images/global/rating-star.svg')
            },
            testimonialStarSvg: function () {
                return require('@/assets/images/global/testimonial-star.svg')
            },
        },
    }
</script>

<style lang="scss">
    @import '../styles/components/review';
</style>
