<template>
    <marketing-layout
        class="abTestLandingV1"
        :has-landing-footer="true"
    >
        <div class="d-none">
            {{ heraclesParameter.EXPERIMENT_NAME }}
        </div>
        <div class="container-fluid px-0">
            <section-block class="pb-sm-1 pt-4 pt-md-8">
                <div
                    id="origination-form-container"
                    class="container"
                >
                    <div class="row">
                        <div class="col-12">
                            <h1 class="text-responsive mb-2 mb-md-0">
                                Credit card in the front.
                            </h1>
                            <h1 class="text-responsive mb-3 mb-md-5">
                                Home equity in the back.
                            </h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-7">
                            <ul class="mb-4">
                                <li>
                                    <h5 class="fw-light">
                                        3.49 – 10.99% Var. APR<sup><a href="#footnote_1">1</a></sup>
                                    </h5>
                                </li>
                                <li>
                                    <h5 class="fw-light">
                                        1.5% Unlimited Cashback<sup><a href="#footnote_1">1</a></sup>
                                    </h5>
                                </li>
                                <li>
                                    <h5 class="fw-light">
                                        Up To $250,000 Limit
                                    </h5>
                                </li>
                                <li>
                                    <h5 class="fw-light">
                                        Starting at $0 To Get A Card
                                    </h5>
                                </li>
                                <li>
                                    <h5 class="fw-light">
                                        As Fast As 15 Minutes
                                    </h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-5">
                            <origination-invite-form
                                id-prefix="main-origination-form"
                                :code-required="codeRequired"
                                :show-forgot-code-option="showForgotCodeOption"
                                :mail-offer-link="{ name: 'invite', path: '/invite' }"
                                ref="originationInviteFormHero"
                                show-safe-credit-score
                                cta-text="Check your offer"
                            />
                        </div>
                    </div>
                </div>
                <div class="container mt-5 mt-md-7 text-center">
                    <section-header variant="text-muted">
                        As featured on
                    </section-header>
                    <div class="d-flex align-items-center flex-wrap justify-content-center mt-2">
                        <div>
                            <img
                                src="@/assets/images/global/bankrate_logo.jpg"
                                alt="BankRate"
                                class="partner-logo me-3 pt-1"
                                width="135"
                            >
                        </div>
                        <div>
                            <img
                                src="@/assets/images/global/lendingtree_logo.jpg"
                                alt="Lending Tree"
                                class="partner-logo me-md-3"
                                width="142"
                            >
                        </div>
                        <div>
                            <img
                                src="@/assets/images/global/trustpilot_logo.jpg"
                                alt="Trust Pilot"
                                class="partner-logo mt-2 mt-md-0"
                                width="164"
                            >
                        </div>
                    </div>
                </div>
            </section-block>

            <div class="mt-lg-7">
                <div
                    class="position-relative"
                    style="z-index: 1000"
                    @click="$refs.videoPlayer.toggleVisibility()"
                    @keydown="$refs.videoPlayer.toggleVisibility()"
                >
                    <video-player-play-button />
                    <img
                        src="@/assets/images/pages/marketing/landingV2_5/video-still.jpg"
                        class="w-100 img-fluid"
                        alt="Aven Card"
                    >
                </div>
                <video-player
                    name="sandwich_349"
                    src="https://www.youtube.com/embed/jiK3m6lm20M?autoplay=1&mute=0&color=white&fs=0&modestbranding=1&rel=0&showinfo=0&enablejsapi=1&origin=https%3A%2F%aven.com"
                    ref="videoPlayer"
                />
            </div>

            <!-- WHAT IT IS -->
            <section-block>
                <div class="container">
                    <div class="row text-md-center">
                        <div class="col-12 col-md-6 offset-md-3">
                            <h3 class="text-responsive">
                                It’s home equity...<br>
                                It’s a credit card...<br>
                                It’s Aven.
                            </h3>
                            <h5 class="fw-light mt-2 mt-md-5">
                                It works like a regular Visa Credit Card, but taps into your home equity to get you really, really low rates.
                            </h5>
                        </div>
                    </div>
                    <div class="row mt-1 mt-md-3 gy-4">
                        <div class="col-12 col-md-6">
                            <img
                                src="@/assets/images/experiments/abTestLandingV1/VisaCard.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Home Equity Rates"
                            >
                            <p>
                                <strong>1.5% Cashback Visa Credit Card</strong><br>
                                Get a credit card that earns you unlimited 1.5% cashback on every single purchase. Use what you want, when you want. Yes, even on that coffee and donut.
                            </p>
                        </div>
                        <div class="col-12 col-md-6">
                            <img
                                src="@/assets/images/experiments/abTestLandingV1/BankAccount.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Credit Card Simplicity & Rewards"
                            >
                            <p>
                                <strong>Cash Out To Your Bank</strong><br>
                                Directly deposit cash from your Aven card to your bank account at the same low rate. No gotchas. Just a one-time 2% transfer fee.<sup><a href="#footnote_1">1</a></sup>
                            </p>
                        </div>
                        <div class="col-12 col-md-6">
                            <img
                                src="@/assets/images/experiments/abTestLandingV1/BalanceTransfer.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Credit Card Simplicity & Rewards"
                            >
                            <p>
                                <strong>Balance Transfer Other Debt</strong><br>
                                Transfer your other high-interest credit card or personal loan debt and start saving thousands in unnecessary interest payments. No gimmicks. Pay the same low rate.
                                Just a one-time 2% transfer fee.<sup><a href="#footnote_1">1</a></sup>
                            </p>
                        </div>
                        <div class="col-12 col-md-6">
                            <img
                                src="@/assets/images/experiments/abTestLandingV1/Payment.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Credit Card Simplicity & Rewards"
                            >
                            <p>
                                <strong>Flexible Repayment Options</strong><br>
                                Only pay for what you use. You can make payments likes a regular credit card – a minimum of 1% of the statement balance plus interest. Or for Balance Transfers and Cash
                                Outs, you also have the option for fixed monthly installment payments.
                            </p>
                        </div>
                    </div>
                </div>
            </section-block>

            <!-- CARD IMAGE -->
            <div class="container-lg d-md-none">
                <div class="col-12">
                    <img
                        src="@/assets/images/pages/marketing/landing/aven-credit-card-reader@2x.jpg"
                        class="img-fluid"
                        alt="Aven Card"
                    >
                </div>
            </div>

            <!-- FEES -->
            <section-block>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-12 col-md-7">
                            <h2 class="text-responsive">
                                No annual fee.<sup><a href="#footnote_1">1</a></sup>
                                <br>
                                Starting at $0 to get.<br>
                            </h2>
                            <h5 class="fw-light mt-2 mt-md-3">
                                Typical home equity products can cost thousands of dollars to originate on top of the weeks-long process. Starting at $0 to get a card and as fast as 15 minutes.
                            </h5>
                        </div>
                        <div class="col-12 col-md-5 mt-4 mt-md-0">
                            <div class="list">
                                <div class="">
                                    <p>Origination Fee</p>
                                    <p class="fw-bold">
                                        $0
                                    </p>
                                </div>
                                <div class="">
                                    <p>Annual Fee</p>
                                    <p class="fw-bold">
                                        $0
                                    </p>
                                </div>
                                <div class="">
                                    <p>Appraisal Fee</p>
                                    <p class="fw-bold">
                                        $0
                                    </p>
                                </div>
                                <div class="">
                                    <p>Notary Fee</p>
                                    <p class="fw-bold">
                                        $0
                                    </p>
                                </div>
                                <div class="">
                                    <p>Prepayment Penalty</p>
                                    <p class="fw-bold">
                                        $0
                                    </p>
                                </div>
                                <div class="">
                                    <p>Escrow Fee</p>
                                    <p class="fw-bold">
                                        $0
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section-block>

            <!-- HOW IT WORKS -->
            <section-block class="bg-light">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-7 order-md-1">
                            <h2 class="text-responsive mb-5">
                                As fast as 15 minutes from start to finish
                            </h2>
                            <div>
                                <div class="d-flex">
                                    <h5 class="me-2 me-md-3">
                                        1.
                                    </h5>
                                    <div>
                                        <h5 class="mb-0 mb-md-1">
                                            Check Offer
                                        </h5>
                                        <p>Answer a few simple questions to quickly confirm your identity and home. <strong>Does not affect your credit score.</strong></p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="d-flex">
                                    <h5 class="me-2 me-md-3">
                                        2.
                                    </h5>
                                    <div>
                                        <h5 class="mb-0 mb-md-1">
                                            Confirm Income
                                        </h5>
                                        <p>
                                            A flexible yet simple process to instantly verify your bank account or pay stubs. You pick which works for you. For some this step is automatically
                                            calculated.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <div class="d-flex">
                                    <h5 class="me-2 me-md-3">
                                        3.
                                    </h5>
                                    <div>
                                        <h5 class="mb-0 mb-md-1">
                                            Digitally Sign Documents
                                        </h5>
                                        <p>We seamlessly connect you with our notary to sign your documents entirely digitally.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-5 order-md-0 mt-3 mt-md-0">
                            <video
                                playsinline
                                autoplay
                                muted
                                loop
                                class="img-fluid"
                            >
                                <source
                                    src="@/assets/images/experiments/abTestLandingV1/SignUpLP_16.mp4"
                                    type="video/mp4"
                                >
                                <track
                                    id="track1"
                                    kind="captions"
                                    srclang="en"
                                    src="@/assets/images/experiments/abTestLandingV1/subtitles-en.vtt"
                                    label="English"
                                    default=""
                                >

                                Sorry, your browser doesn't support embedded videos.
                            </video>
                        </div>
                    </div>
                </div>
            </section-block>

            <ticker-section />

            <card-comparison-section />

            <!-- TESTIMONIAL -->
            <section-block>
                <div>
                    <div
                        class="trustpilot-widget"
                        data-locale="en-US"
                        data-template-id="5419b732fbfb950b10de65e5"
                        data-businessunit-id="60db6c251ceb430001e0cfff"
                        data-style-height="24px"
                        data-style-width="100%"
                        data-theme="light"
                        data-tags="SelectedReview"
                        data-text-color="#000000"
                    >
                        <a
                            href="https://www.trustpilot.com/review/aven.com"
                            target="_blank"
                            rel="noopener"
                        >Trustpilot</a>
                    </div>
                    <div class="reviews container-lg mt-3">
                        <div
                            v-for="(review, index) in reviews"
                            :key="index"
                        >
                            <review
                                :name="review.name"
                                :state="review.state"
                                :rating="review.rating"
                                :content="review.content"
                                theme="trustpilot"
                            />
                        </div>
                    </div>
                </div>
            </section-block>

            <!-- PEACE OF MIND -->
            <section-block>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-12 col-md-7">
                            <h2>Peace of mind</h2>
                            <h5 class="fw-light">
                                We want to give you the lowest rate, not the fear of using home equity. So we have built-in safeguards to help remove that fear, especially during inevitable life
                                surprises.
                            </h5>
                        </div>
                        <div class="col-12 col-md-5">
                            <img
                                src="@/assets/images/experiments/abTestLandingV1/PeaceOfMind.jpg"
                                class="img-fluid"
                                alt="Aven Card"
                            >
                        </div>
                    </div>
                    <div class="row mt-5 mt-md-8">
                        <div class="col-12 col-md-6">
                            <img
                                src="@/assets/images/experiments/abTestLandingV1/BankAccount.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Backed By FDIC Insured Bank"
                            >
                            <p>
                                <strong>Backed By FDIC Insured Bank</strong><br>
                                We are partnered with Coastal Community Bank, member FDIC, pursuant to license by Visa USA Inc. You benefit from our innovation, great pricing, and simple convenience
                                with the trust of a traditional FDIC-insured bank.
                            </p>
                        </div>
                        <div class="col-12 col-md-6">
                            <img
                                src="@/assets/images/experiments/abTestLandingV1/Bell.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Built In Safeguards"
                            >
                            <p>
                                <strong>Built In Safeguards</strong><br>
                                Tools to keep control over your spend and debt. Everytime you purchase, you get an alert on your app. Everytime your card is used for a large purchase, your app will
                                get alerts to approve those big purchases.
                            </p>
                        </div>
                    </div>
                </div>
            </section-block>

            <section-block class="bg-light">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-12 col-md-6">
                            <h2 class="text-responsive">
                                The card for everything
                            </h2>
                            <h5 class="fw-light">
                                Broken pipe? Bathroom upgrade? Too much credit card interest? Use what you need, when you need. Yes, even just a donut.
                            </h5>
                            <p class="mt-4">
                                <strong>P.S. Did you know home improvement projects may be considered tax deductible!</strong> Consult a tax advisor regarding the deductibility of interest and
                                charges.
                            </p>
                        </div>
                        <div class="col-12 col-md-6">
                            <div
                                id="useCaseCarousel"
                                class="carousel slide"
                                data-ride="carousel"
                            >
                                <div class="carousel-inner rounded-3">
                                    <div class="carousel-item active">
                                        <img
                                            src="@/assets/images/pages/marketing/landing/FreshPaint@2x.jpg"
                                            class="d-block w-100"
                                            alt="Deduct interest on your home projects"
                                        >
                                    </div>
                                    <div class="carousel-item">
                                        <img
                                            src="@/assets/images/pages/marketing/landing/UpdateKitchen@2x.jpg"
                                            class="d-block w-100"
                                            alt="Deduct interest on your home projects"
                                        >
                                    </div>
                                    <div class="carousel-item">
                                        <img
                                            src="@/assets/images/pages/marketing/landing/NewYard@2x.jpg"
                                            class="d-block w-100"
                                            alt="Deduct interest on your home projects"
                                        >
                                    </div>
                                    <div class="carousel-item">
                                        <img
                                            src="@/assets/images/pages/marketing/landing/HomeRepairs@2x.jpg"
                                            class="d-block w-100"
                                            alt="Deduct interest on your home projects"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section-block>

            <section-block class="bg-light">
                <div class="container mb-5">
                    <h2 class="text-responsive mb-4">
                        Questions?
                    </h2>
                    <div class="row">
                        <div class="mb-5 col-md-6">
                            <img
                                src="@/assets/images/pages/marketing/education/whatIcon.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Credit Card Icon"
                            >
                            <div class="fw-bold">
                                {{ $t('pages.education.whatTitle') }}
                            </div>
                            <div v-html="$t('pages.education.whatSubtitle')" />
                        </div>
                        <div class="mb-5 col-md-6">
                            <img
                                src="@/assets/images/pages/marketing/education/howIcon.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="House Icon"
                            >
                            <div class="fw-bold">
                                {{ $t('pages.education.howTitle') }}
                            </div>
                            <div v-html="$t('pages.education.howSubtitle')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="mb-5 col-md-6">
                            <img
                                src="@/assets/images/pages/marketing/education/rateIcon.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Percent Sign Icon"
                            >
                            <div class="fw-bold">
                                {{ $t('pages.education.rateTitle') }}
                            </div>
                            <div v-html="$t('pages.education.rateSubtitle')" />
                        </div>
                        <div class="mb-5 col-md-6">
                            <img
                                src="@/assets/images/pages/marketing/education/paymentIcon.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Calculator Icon"
                            >
                            <div class="fw-bold">
                                {{ $t('pages.education.paymentTitle') }}
                            </div>
                            <div v-html="$t('pages.education.paymentSubtitle')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="mb-5 col-md-6">
                            <img
                                src="@/assets/images/pages/marketing/education/cashIcon.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Cash Icon"
                            >
                            <div class="fw-bold">
                                {{ $t('pages.education.cashTitle') }}
                            </div>
                            <div v-html="$t('pages.education.cashSubtitle')" />
                        </div>
                        <div class="mb-5 col-md-6">
                            <img
                                src="@/assets/images/pages/marketing/education/creditScoreIcon.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Meter Icon"
                            >
                            <div class="fw-bold">
                                {{ $t('pages.education.creditScoreTitle') }}
                            </div>
                            <div v-html="$t('pages.education.creditScoreSubtitle')" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="mb-5 col-md-6">
                            <img
                                src="@/assets/images/pages/marketing/education/feesIcon.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Price Tag Icon"
                            >
                            <div class="fw-bold">
                                {{ $t('pages.education.feesTitle') }}
                            </div>
                            <div v-html="$t('pages.education.feesSubtitle')" />
                        </div>
                        <div class="mb-5 col-md-6">
                            <img
                                src="@/assets/images/pages/marketing/education/rateIcon.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Price Tag Icon"
                            >
                            <div class="fw-bold">
                                Why is the rate variable?
                            </div>
                            <div
                                v-html="
                                    `Similar to other credit cards, the rate is variable. It will vary based on the Prime Rate as published in the Wall Street Journal, outside Aven's control. As the Prime Rate shifts up or down, so will the APR on the Aven Card and any other variable-rate credit cards, lines, loans. The prime rate may change several times a year or may go months or years without change. For Balance Transfers & Cash Out transactions - Cardholders may choose a fixed Monthly Payment option - which can be 10 or 15 years in length.`
                                "
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="mb-5 col-md-6">
                            <img
                                src="@/assets/images/pages/marketing/education/howIcon.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Price Tag Icon"
                            >
                            <div class="fw-bold">
                                Why not get a home equity line of credit instead?
                            </div>
                            <div
                                v-html="
                                    `Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-6 weeks to close.<sup>3</sup>

With Aven, you can get your card in just a few days in the mail, you can use it any number of times for any amount under your limit, with no origination or annual fees. Best of all, you get 1.5% cash back on your purchases.<sup>1</sup>`
                                "
                            />
                        </div>
                        <div class="mb-5 col-md-6">
                            <img
                                src="@/assets/images/experiments/abTestLandingV1/Document.svg"
                                width="32"
                                height="32"
                                class="mb-2"
                                alt="Price Tag Icon"
                            >
                            <div class="fw-bold">
                                What are the criteria to qualify for this credit card?
                            </div>
                            <div
                                v-html="
                                    `1) Own and have equity in your home.
2) Have an income that can support your monthly bill – we don’t want you to get into debt that you can’t afford.
3) Meet certain credit criteria, including credit score, income, and property value requirements.`
                                "
                            />
                        </div>
                    </div>
                </div>
            </section-block>

            <section-block>
                <div class="container">
                    <div class="row gy-3">
                        <div class="col-12 col-md-6">
                            <div class="bg-light rounded-3 px-3 py-5 px-md-5 py-md-7">
                                <img
                                    src="@/assets/images/pages/marketing/landing/CreditCardIcon.svg"
                                    width="48"
                                    height="48"
                                    alt="Contact Us"
                                    class="mb-3"
                                >
                                <h3 class="mb-3">
                                    Credit card in the front. HELOC in the back.
                                </h3>
                                <h5 class="mb-3 fw-light">
                                    Starting at $0 to get. Checking your offer will <strong>NOT</strong> affect your credit score
                                </h5>
                                <origination-invite-form
                                    id-prefix="footer-origination-form"
                                    class="mw-invite-form"
                                    :code-required="codeRequired"
                                    :show-forgot-code-option="showForgotCodeOption"
                                    :mail-offer-link="{ name: 'invite', path: '/invite' }"
                                    ref="originationInviteFormFooter"
                                    :show-safe-credit-score="false"
                                    cta-text="Check your offer"
                                />
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="bg-light rounded-3 px-3 py-5 px-md-5 py-md-7 h-100">
                                <img
                                    src="@/assets/images/pages/marketing/landing/PhoneIcon.svg"
                                    width="48"
                                    height="48"
                                    alt="Contact Us"
                                    class="mb-3"
                                >
                                <h3 class="mb-3">
                                    Reach out with<br>
                                    any questions
                                </h3>
                                <h5
                                    class="mb-3 fw-light"
                                    v-html="$t('pages.marketing.landing.reengage.contactInfoHtml')"
                                />
                                <a
                                    @click="showWebWidget"
                                    class="btn btn-secondary w-100"
                                >
                                    {{ $t('pages.marketing.landing.reengage.messageUs') }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section-block>
        </div>
    </marketing-layout>
</template>

<script>
    import Marketing from '@/layouts/Marketing'
    import { i18n } from '@/utils/i18n'
    import SectionBlock from '@/components/SectionBlock'
    import { contact_info } from '@/utils/contact-info-dictionary'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { Flows } from '@/flow/flowController'
    import SectionHeader from '@/components/SectionHeader'
    import OriginationInviteForm from '@/components/OriginationInviteForm'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import CardComparisonSection from '@/components/landing/CardComparisonSection'
    import { logger } from '@/utils/logger'
    import VideoPlayerPlayButton from '@/components/VideoPlayerPlayButton'
    import VideoPlayer from '@/components/VideoPlayer'
    import { reloadPageWithCleanState } from '@/utils/routerUtils'
    import TickerSection from '@/components/landing/TickerSection'
    import { reviews } from '@/utils/reviews'
    import Review from '@/components/Review'
    import { Carousel } from 'bootstrap'
    import zendeskMixin from '@/mixins/zendeskMixin'

    const SITE_VERSION = '2.5'
    export default {
        name: 'Default20220323Landing',
        metaInfo: {
            title: 'Aven HELOC Card: A Credit Card backed by Home Equity',
            meta: [{ name: 'description', content: String(i18n.t('pages.marketing.landing.description')) }],
        },
        props: {
            codeRequired: {
                type: Boolean,
                required: true,
            },
            showForgotCodeOption: {
                type: Boolean,
                required: true,
            },
        },
        components: {
            'ticker-section': TickerSection,
            'card-comparison-section': CardComparisonSection,
            'origination-invite-form': OriginationInviteForm,
            'section-header': SectionHeader,
            'section-block': SectionBlock,
            'marketing-layout': Marketing,
            'video-player-play-button': VideoPlayerPlayButton,
            'video-player': VideoPlayer,
            review: Review,
        },
        mixins: [experimentsMixin, zendeskMixin],
        created: function () {
            appSessionStorage.setItem(sessionStorageKey.currentFlow, Flows.origination)
        },
        mounted: function () {
            if (appSessionStorage.getItem(sessionStorageKey.jwtTokens)) {
                logger.info(`User went to landing page with jwt tokens! Clearing their stale state`)
                return reloadPageWithCleanState()
            }

            const myCarousel = document.getElementById('useCaseCarousel')
            const carousel = new Carousel(myCarousel, {
                interval: 1500,
            })
            carousel.cycle()

            this.logViewEvent()
        },
        computed: {
            smsHref() {
                return `sms:${contact_info.sms}`
            },
            reviews: function () {
                return reviews.slice(0, 3)
            },
        },
        methods: {
            logViewEvent: function () {
                this.$logEvent('view_landing_default', { query: window.location.search, version: SITE_VERSION })
            },
        },
    }
</script>

<style lang="scss">
    @import '../../../../styles/pages/marketing/landing';
    @import '../../../../styles/pages/marketing/abTestLandingV1';
    @import '../../../../styles/components/feesSection';
    @import '../../../../styles/components/review';
    @import '../../../../styles/components/reviewsSection';
</style>
