<template>
    <div
        class="video-player"
        v-if="visible"
    >
        <div
            class="video-container"
            @click="toggleVisibility()"
            @keydown="toggleVisibility()"
        >
            <iframe
                class="video-player-iframe"
                frameborder="0"
                allowfullscreen="1"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                width="640"
                height="360"
                :src="src"
            />
            <a
                class="video-close-button"
                title="Close"
            >
                <img
                    src="@/assets/images/components/videoPlayer/video-close.svg"
                    width="32"
                    height="32"
                    alt="Close"
                >
            </a>
        </div>
    </div>
</template>
<script>
    import DateTime from 'luxon/src/datetime'

    export default {
        name: 'VideoPlayer',
        props: {
            name: {
                type: String,
                required: true,
            },
            src: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                visible: false,
                scrollY: window.scrollY,
                startDateTime: DateTime.now().toUTC(),
            }
        },
        methods: {
            toggleVisibility() {
                if (this.visible) {
                    // Hide Modal
                    this.visible = false
                    document.querySelector('body').style.overflow = 'visible'
                    this.$logEvent('view_video_time_milliseconds', { date_time: DateTime.now().toUTC() - this.startDateTime })
                    console.log(DateTime.now().toUTC() - this.startDateTime)
                } else {
                    // Show Modal
                    this.visible = true
                    document.querySelector('body').style.overflow = 'hidden'
                    this.startDateTime = DateTime.now().toUTC()
                    this.$logEvent('view_video', { video: this.name })
                }
            },
        },
    }
</script>
<style lang="scss" scoped>
    @import '../styles/components/videoPlayer';
</style>
