// the order of the elements affects SocialProofListItem
export const reviews = [
    {
        rating: 5,
        content:
            'Can’t believe everything, from start to finish, took place in couple hours. In my case, it probably took more time because of a technical glitch on my side. Initially I liked Aven’s idea but was skeptical, but later I fell In love with it after I saw their process and approach in delivering what they promise. To me getting a HELOC approval in couple hours is like recent space trips taken by Branson and Bezos... that are quick, accurate, and fascinating. The astonishing part is the virtual notary. When everything is online in this digital age, why not notary? Thanks to technology and Aven. HELOC approval is no longer a painful process.',
        name: 'Vasu',
        state: 'California',
        title: 'Thanks to Aven and it’s innovative idea',
        createdDate: new Date('2021-07-28T20:32:07.000Z'),
        updatedDate: new Date('2021-07-28T20:40:05.000Z'),
    },
    {
        rating: 5,
        content:
            'This new product is amazing! I tried to get HELOC on my properties through a bank and through credit union but I was not able to. Just when I gave up, this Aven offer came in the mail. I worked with Jag. Spoke  with him a few times before I actually applied. He was super helpful and responsive, as I had a few glitches. I applied in the evening and was approved and ready to sign with an online notary the next morning. Really happy with the process. HELOC is slightly better option, but depending on the goal this product can work just great. Thank you!',
        name: 'Nina',
        state: 'California',
        title: 'This new product is amazing',
        publishedDate: new Date('2021-08-26T00:28:04.000Z'),
        updatedDate: null,
    },
    {
        rating: 5,
        content:
            'I was skeptical about this product after going to banking institutions. Aven is the real deal,they are honest and they answered  all my questions and addressed my concerns. The process took less than 1 day. Jag and Camille went over and beyond. No hidden agendas and no fine print . Def recommend Aven',
        name: 'Mary',
        state: 'California',
        title: 'I was skeptical about this product…',
        createdDate: new Date('2021-08-12T18:49:59.000Z'),
        updatedDate: null,
    },
    {
        rating: 5,
        content:
            'I’m so glad I chose Aven, I got a great rate, and was able to get rid of high interest debt. I had a little trouble trying to get money transferred, so I called, and was taken care of right away! I didn’t have to worry, these guys handled the hard stuff for me! Forever grateful Aven team!',
        name: 'Vivian',
        state: 'California',
        title: 'I’m so glad I chose Aven',
        createdDate: new Date('2021-07-09T14:33:35.000Z'),
        updatedDate: '2021-07-09T14:34:01.000Z',
    },
    {
        rating: 5,
        content:
            'Not only did they explain everything and walk me through my loan step by step but they also reached out three months later to wish me well and remind me to call if I have any questions! Customer service is above and beyond, thanks guys!',
        name: 'Wil',
        state: 'California',
        title: 'Top-notch',
        createdDate: new Date('2021-07-02T02:47:55.000Z'),
        updatedDate: null,
    },
    {
        rating: 5,
        content: 'Quick and very easy process! Thanks Mark and the whole Aven team!',
        name: 'Eddie',
        state: 'California',
        title: 'Quick and very easy process',
        createdDate: new Date('2021-07-10T17:48:30.000Z'),
        updatedDate: null,
    },
    {
        rating: 5,
        content: 'Had a problem with a balance transfer. AVEN recognized the problem and worked with me to solve the issue and waived all fees. Great group to work with.',
        name: 'Jeffrey',
        state: 'California',
        title: 'Had a problem with a balance transfer',
        createdDate: new Date('2021-07-10T17:42:34.000Z'),
        updatedDate: null,
    },
    {
        rating: 5,
        content:
            'I missed a payment date while on vacation and Carl from Aven reached out to me directly and also offered to reverse the late fee for me. I am so impressed by the level of customer support and the ease of reaching a real person with this company. I would recommend this card to anyone.',
        name: 'Heather',
        state: 'California',
        title: 'Customer service provided by real people',
        createdDate: new Date('2021-07-29T19:59:20.000Z'),
        updatedDate: null,
    },
    {
        rating: 5,
        content:
            'I wanted to find out how long it takes for the balance transfer to pay to my capital one account so i sent a text. the customer service team responded quickly and provided the information i needed! every inquiry i have had was answered quickly and accurately! thank you!',
        name: 'Roni',
        state: 'California',
        title: 'I wanted to find out how long it takes…',
        createdDate: new Date('2021-07-10T17:54:28.000Z'),
        updatedDate: null,
    },
    {
        rating: 5,
        name: 'Neil',
        state: 'California',
        title: 'Great service',
        createdDate: new Date('2021-07-28T00:58:52.000Z'),
        updatedDate: null,
    },
    {
        rating: 5,
        content:
            'The wife and I did a video slash doc u sign notary. The young lady was awesome and she explained everything very well. Made it very easy. She has a great attitude and it reflects in her professionalism.',
        name: 'Andrei',
        state: 'California',
        title: 'Video slash doc u sign',
        publishedDate: new Date('2021-10-31T06:32:39.000Z'),
        updatedDate: null,
    },
    {
        rating: 5,
        content:
            "Aven has been wonderful. Very good CS. Very efficient, couldn't be a simpler process... ordering a book on amazon is more complicated. :) I only hope that their CS remains as good as it is today as they scale and grow....",
        name: 'Robbie',
        state: 'California',
        title: 'Aven has been wonderful',
        publishedDate: new Date('2021-10-18T22:18:05.000Z'),
        updatedDate: null,
    },
    {
        rating: 5,
        content:
            'I would like to state, I am so greatful to Jag for making it possible in getting a line of equity which I never thought was possible and in a time frame of knowing in 5mins I was approved and every step of the way Jag walked me through the whole process. With his team, I experienced the customer service was outstanding through all aspects of phone calls text messages. I will recommend to all my friends. Outstanding job 👍',
        name: 'Jas',
        state: 'California',
        title: 'I would like to state',
        publishedDate: new Date('2021-10-14T04:27:09.000Z'),
        updatedDate: null,
    },
    {
        rating: 5,
        content:
            'I didn’t receive the card within 10 business days as promised, and somehow the card got lost. Upon my notification to the support team, they fix the problem right away by sending me the card via express delivery. I got the card two days later. The service personnel is very apologetic and nice to offer me a credit to my Aven card for the delay. The card is easy to use in the app and I received the fund within a day. Overall, I am very happy with the service and support from Aven.',
        name: 'R.Lee',
        state: 'California',
        title: 'Excellent service!',
        publishedDate: new Date('2021-10-13T20:50:45.000Z'),
        updatedDate: new Date('2021-10-13T20:52:58.000Z'),
    },
    {
        rating: 5,
        content:
            'To whom it may concern, Jag was a outstanding person work with. He went out of his way to help me with all I needed. I really wish there were more customer service people out there like Jag. Your company should be very glad to have him on your team. Thank you for everything',
        name: 'Ed',
        state: 'California',
        title: 'To whom it may concern',
        publishedDate: new Date('2021-09-02T23:10:33.000Z'),
        updatedDate: null,
    },
    {
        rating: 5,
        content: 'Jag did a really great job! Pretty simple process!',
        name: 'Joe',
        state: 'California',
        title: 'Jag did a really great job',
        publishedDate: new Date('2021-08-27T01:10:43.000Z'),
        updatedDate: null,
    },
]
